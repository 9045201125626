export const getCreatePersonParameters = function (formValues, idType) {
  const createPersonParameters = {
    id: null,
    reportId: null,
    name: null,
    socialSecurityNumber: null,
    legalGender: null,
    gender: null,
    yearOfBirth: null,
    personId: null,
    customerNo: null,
  };
  switch (idType) {
    case 'customerNumber': {
      createPersonParameters.legalGender = formValues?.gender === 'Man' ? 'Male' : 'Female';
      createPersonParameters.gender = formValues?.gender === 'Man' ? 'Male' : 'Female';
      createPersonParameters.yearOfBirth = formValues.birthYear;
      createPersonParameters.customerNo = formValues.personId;
      createPersonParameters.name = formValues.personFullName;
      break;
    }
    case 'socialNumber': {
      createPersonParameters.yearOfBirth = parseInt(formValues.personId.slice(0, 4));
      createPersonParameters.legalGender = parseInt(formValues.personId.charAt(10)) % 2 == 0 ? 'Female' : 'Male';
      createPersonParameters.gender = parseInt(formValues.personId.charAt(10)) % 2 == 0 ? 'Female' : 'Male';
      createPersonParameters.socialSecurityNumber = formValues.personId;
      createPersonParameters.name = formValues.personFullName;
      break;
    }
    case 'personId': {
      createPersonParameters.yearOfBirth = parseInt(formValues.personId.substr(formValues.personId.length - 4));
      createPersonParameters.legalGender = formValues.personId.charAt(3).toUpperCase() === 'M' ? 'Male' : 'Female';
      createPersonParameters.gender = formValues.personId.charAt(3).toUpperCase() === 'M' ? 'Male' : 'Female';
      createPersonParameters.personId = formValues.personId;
      createPersonParameters.name = formValues.personFullName;
      break;
    }
  }
  return createPersonParameters;
};
