import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { searchQuery } from 'state/global/searchQuery';
import { businessTypesFiltered } from 'state/global/businessTypes';
import useFetchBusinessTypes from 'hooks/api/businessType/useFetchBusinessTypes';
import useFetchRegionList from 'hooks/api/region/useFetchRegionList';
import useSaveBusinessType from 'hooks/api/businessType/useSaveBusinessType';

import filterBySearch from 'utils/global/filterBySearch';
import tableElements from 'utils/KubenAdmin/BusinessValuesPage/tableElements';
import businessValuesTableMenu from 'utils/KubenAdmin/BusinessValuesPage/businessValuesTableMenu';
import getUUID from 'utils/KubenAdmin/getUUID';

import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIListModal from 'components/global/UIModals/UIListModal';

import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UITableMenu from 'components/KubenAdmin/UITable/UITableMenu';

const BusinessValuesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [search, setSearch] = useRecoilState(searchQuery);
  const businessStaircasesFiltered = useRecoilValue(businessTypesFiltered);
  const [selected, setSelected] = useState(null);
  const [isCopyBusinessValues, setCopyBusinessValues] = useState(false);

  const {
    data: businessTypes,
    isLoading: isBusinessTypesLoading,
    isError: isBusinessTypesError,
    refetch: refetchBusinessTypes,
  } = useFetchBusinessTypes();
  const { data: regions, isLoading: isRegionsLoading, isError: isRegionsError } = useFetchRegionList();
  const {
    isLoading: isCopyBusinessTypeLoading,
    isError: isCopyBusinessTypeError,
    isSuccess: isCopyBusinessTypeSuccess,
    mutate: mutateCopyBusinessType,
  } = useSaveBusinessType();

  const filteredRegions = regions
    ? filterBySearch(search, regions).filter((region) => {
        const regionHasOverride = businessTypes?.some(
          (businessType) => businessType.region?.id === region.id && businessType.id === selected?.id,
        );

        return !regionHasOverride;
      })
    : [];

  const handleClick = (type, id, regionid) => {
    if (type === 'editStair') {
      if (regionid !== undefined) {
        history.push(`/admin/businessvalues/staircase/${id}/${regionid}`);
      } else {
        history.push(`/admin/businessvalues/staircase/${id}`);
      }
    } else if (type === 'edit') {
      if (regionid !== undefined) {
        history.push(`/admin/businessvalues/${id}/${regionid}`);
      } else {
        history.push(`/admin/businessvalues/${id}`);
      }
    } else if (type === 'copy') {
      setCopyBusinessValues(true);
    }
  };

  const onCopyBusinessValues = (id, element) => {
    const matchingBusinessType = businessTypes.find(
      (businessType) => businessType.id === id && businessType.region === null,
    );

    const updatedBusinessValues = {
      ...matchingBusinessType,
      region: {
        id: element.id,
        name: element.name,
      },
      referenceDataVersions: matchingBusinessType.referenceDataVersions.map((version) => ({
        ...version,
        id: getUUID(),
      })),
    };

    mutateCopyBusinessType(updatedBusinessValues, {
      onSuccess: () => {
        history.push(`/admin/businessvalues/${id}/${element.id}`);
      },
    });
  };

  useEffect(() => {
    refetchBusinessTypes();
  }, [isCopyBusinessTypeSuccess]);

  if (isBusinessTypesLoading || isRegionsLoading || isCopyBusinessTypeLoading) {
    return <UILoadingModal />;
  }

  if (isBusinessTypesError || isRegionsError || isCopyBusinessTypeError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="VERKSAMHETSVÄRDEN"
        isButton={false}
      />
      <UICardWrapper title="Sök på behovstrappor efter verksamhetsområde">
        <UIInput
          type="search"
          placeholder="Sök"
          value={search}
          onChange={(text) => setSearch(text)}
        />
      </UICardWrapper>
      <UITable tableElements={tableElements}>
        {businessStaircasesFiltered.length > 0 ? (
          businessStaircasesFiltered.map((el, idx) => {
            const activeVersion = el.referenceDataVersions[el.referenceDataVersions.length - 1];
            const modifiedMenu = el.region
              ? businessValuesTableMenu.filter((option) => option.type !== 'copy')
              : businessValuesTableMenu;
            return (
              <UITableElementWrapper key={idx}>
                <UITableElement
                  name={el.name}
                  maxLength={100}
                />
                <UITableElement
                  name={el.region?.name}
                  maxLength={100}
                />
                <UITableElement
                  name={activeVersion.startDate}
                  maxLength={100}
                />
                <UITableMenu
                  handleClick={(type) => handleClick(type, el.id, el.region?.id)}
                  isOpen={selected?.id === el.id && selected?.region?.id === el.region?.id}
                  setOpen={() => {
                    if (selected && selected.id === el.id && selected.region?.id === el.region?.id) {
                      setSelected(null);
                    } else {
                      setSelected(el);
                    }
                  }}
                  data={modifiedMenu}
                />
              </UITableElementWrapper>
            );
          })
        ) : (
          <div className="py-8">Inga värden att visa</div>
        )}
      </UITable>
      {isCopyBusinessValues && (
        <UIListModal
          modalTitle="Kopiera verksamhetsvärden"
          isOpen={true}
          data={filteredRegions}
          onClose={() => setCopyBusinessValues(false)}
          handleClick={(element) => onCopyBusinessValues(selected.id, element)}
          isSearchBar={true}
        />
      )}
    </UIStaticComponentsWrapper>
  );
};

export default BusinessValuesPage;
