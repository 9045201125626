import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';

const useSaveState = () => {
  return mutation.useMutationWrapper(async (data) => {
    const resData = await actions.saveState(data);
    return resData;
  });
};

export default useSaveState;
