import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/person';
import { getCreatePersonParameters } from 'utils/KubenAssessments/getCreatePersonParameters';
import { selectedPerson } from 'state/KubenAssessments/saved/savedSelectedPerson';

const useCreateNewPerson = (formValues, idType) => {
  const setSelectedPerson = useSetRecoilState(selectedPerson);

  return mutation.useMutationWrapper(
    async () => {
      const createParameters = getCreatePersonParameters(formValues, idType);
      const createdPerson = await actions.createNewPerson(createParameters);
      setSelectedPerson(createdPerson?.data);
      return createdPerson;
    },
    { retry: false },
  );
};

export default useCreateNewPerson;
