import client from './clients/base';

const URL = 'person/';

const personDelete = async (id) => {
  const response = await client.delete(`${URL}delete/${id}`);
  return response.data;
};

const personSearch = async (body) => {
  const response = await client.post(`${URL}search`, body);
  return response.data;
};

const personEdit = async (body) => {
  const response = await client.post(`${URL}save`, body);
  return response.data;
};

const createNewPerson = (createParameters) => {
  return client.post(`${URL}save`, createParameters);
};

const personDetails = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const fetchSelectedPerson = (fetchParameters) => {
  return client.post(`${URL}query`, fetchParameters);
};

const removePersonTag = (body) => {
  return client.post(`${URL}remove-tag`, body);
};

const addPersonTag = (body) => {
  return client.put(`${URL}add-tag`, body);
};

const fetchPersonTags = (personId, regionId) => {
  return client.get(`${URL}tags/${personId}/${regionId}`);
};

const fetchGenders = async () => {
  const response = await client.get(`${URL}genders/`);
  return response.data;
};

const actions = {
  personDelete,
  personSearch,
  personEdit,
  personDetails,
  fetchSelectedPerson,
  createNewPerson,
  fetchPersonTags,
  removePersonTag,
  addPersonTag,
  fetchGenders,
};

export default actions;
