import query from '../useQueryWrapper';
import actions from 'api/person';

const useFetchGenders = () => {
  return query.useQueryWrapper(
    'fetchGenders',
    async () => {
      const genders = await actions.fetchGenders();
      return genders;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchGenders;
