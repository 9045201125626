import { React } from 'react';
import propTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { isReadOnlyState as isReadOnlyStateRecoil } from 'state/KubenAdmin/regions';

import UIBackButton from '../../global/UIButtons/UIBackButton';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIStaticComponentsWrapper from '../../global/UICommon/UIStaticComponentsWrapper';
import UIFoldableCard from '../UIAdmin/UIFoldableCard';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';

import RegionInput from 'components/KubenAdmin/RegionsPage/RegionInput';
import RegionUnitForm from 'components/KubenAdmin/RegionsPage/RegionUnitForm';
import RegionModal from 'components/KubenAdmin/RegionsPage/RegionModal';
import RegionOrganizationUnitForm from './RegionOrganizationUnitForm';
import RegionUnitVersionsForm from './RegionUnitVersionsForm';

const RegionForm = ({
  pageTitle,
  isAdd = false,
  onSave,
  element,
  setElement,
  versionToEdit,
  setVersionToEdit,
  dropdownElements,
  typeSelection,
  setTypeSelection,
  nightStaffings,
  unitTypes,
  businessTypes,
  parentNode,
  onEditVersion,
  isCurrentVersionEdit,
  onOpenAddNewVersionModal,
  onChangeVersion,
  onDeleteElement,
  onElementAction,
  openModal,
  setOpenModal,
  modalAction,
  elementForModal,
  setElementForModal,
  onSaveElement,
}) => {
  const readOnly = useRecoilValue(isReadOnlyStateRecoil);
  const checkIfEnabled = () => {
    if (element.name != '') {
      if (nightStaffings.length > 0 && unitTypes.length === 0 && versionToEdit.nightStaffingId != null) {
        return true;
      }
      if (nightStaffings.length === 0 && unitTypes.length > 0 && versionToEdit.unitTypeId != null) {
        return true;
      }
      if (
        nightStaffings.length > 0 &&
        unitTypes.length > 0 &&
        versionToEdit.unitTypeId != null &&
        versionToEdit.nightStaffingId != null
      ) {
        return true;
      }
      if (nightStaffings.length === 0 && unitTypes.length === 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <div className="space-y-12 w-full relative">
        <div>
          <div className="absolute z-20">
            <UIBackButton />
          </div>
          <UITitle
            title={pageTitle}
            isButton={false}
            onClick={onSave}
          />
        </div>
        <UIFoldableCard
          title={element.name}
          defaultOpen={true}
        >
          {isAdd ? (
            <RegionInput
              value={typeSelection}
              onChange={(e) => setTypeSelection(e)}
              dropdownElements={dropdownElements}
              title="TYP"
              isDropdown={true}
              isReadonly={readOnly}
            />
          ) : (
            <RegionInput
              value={element?.$type == 'organizationUnit' ? 'Organisationsenhet' : 'Enhet'}
              title="TYP"
              isReadonly={true}
            />
          )}
          <RegionInput
            onChange={(e) => setElement({ ...element, id: e.target.value })}
            value={element?.id}
            title="ID"
            isReadonly={true}
          />
          <RegionInput
            onChange={(e) => setElement({ ...element, name: e.target.value, title: e.target.value })}
            value={element?.name}
            title="Namn"
            isRequired={true}
          />
          {typeSelection === 'unit' ? (
            <RegionUnitForm
              setElement={setElement}
              element={element}
            />
          ) : (
            <RegionOrganizationUnitForm
              setElement={setElement}
              element={element}
              businessTypes={businessTypes}
              parentNode={parentNode}
              isAdd={isAdd}
              unitTypes={element.unitTypes != null ? element.unitTypes : []}
              nightStaffings={element.nightStaffings != null ? element.nightStaffings : []}
              onDeleteElement={onDeleteElement}
              onElementAction={onElementAction}
            />
          )}
        </UIFoldableCard>
        {typeSelection === 'unit' && (
          <RegionUnitVersionsForm
            // trigger to rerender while changing version
            referenceVersions={element.referenceDataVersions}
            element={versionToEdit}
            setElement={setVersionToEdit}
            unitTypes={unitTypes}
            nightStaffings={nightStaffings}
            onEditVersion={onEditVersion}
            isReadonly={!isCurrentVersionEdit && !isAdd}
            onOpenAddNewVersionModal={onOpenAddNewVersionModal}
            isAdd={isAdd}
            onChangeVersion={onChangeVersion}
          />
        )}
        <UIButtonSubmit
          name={`${
            isAdd
              ? 'Lägg till ' + (typeSelection === 'unit' ? 'denna enheten' : 'detta verksamhetsområdet')
              : 'Spara ändringar'
          }`}
          variant="mx-auto"
          onSubmit={onSave}
          assessments={true}
          enabled={checkIfEnabled()}
          isIcon={false}
        />
      </div>
      {openModal && (
        <RegionModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          action={modalAction}
          element={elementForModal}
          setElement={setElementForModal}
          onSave={onSaveElement}
        />
      )}
    </UIStaticComponentsWrapper>
  );
};

RegionForm.propTypes = {
  pageTitle: propTypes.string,
  isAdd: propTypes.bool,
  onSave: propTypes.func,
  element: propTypes.object,
  setElement: propTypes.func,
  versionToEdit: propTypes.object,
  setVersionToEdit: propTypes.func,
  dropdownElements: propTypes.array,
  typeSelection: propTypes.string,
  setTypeSelection: propTypes.func,
  nightStaffings: propTypes.array,
  unitTypes: propTypes.array,
  businessTypes: propTypes.array,
  parentNode: propTypes.object,
  onEditVersion: propTypes.func,
  isCurrentVersionEdit: propTypes.bool,
  onOpenAddNewVersionModal: propTypes.func,
  onChangeVersion: propTypes.func,
  onDeleteElement: propTypes.func,
  onElementAction: propTypes.func,
  openModal: propTypes.bool,
  setOpenModal: propTypes.func,
  modalAction: propTypes.string,
  elementForModal: propTypes.object,
  setElementForModal: propTypes.func,
  onSaveElement: propTypes.func,
};

export default RegionForm;
