import client from './clients/base';

const URL = 'assessmentResult/';

const fetchAnswersAssessment = async (type, templateId, regionId, personId) => {
  if (type === 'person') {
    const response = await client.post(`${URL}create-for-person/${personId}/${templateId}/${regionId}`);
    return response;
  } else {
    const response = await client.post(`${URL}create-for-user/${templateId}/${regionId}`);
    return response;
  }
};

const fetchIncompleteAssessment = async (id) => {
  return await client.get(`${URL}incomplete/${id}`);
};

const createReassessmentFromCompletedAssessment = async (id) => {
  return await client.post(`${URL}create-for-assessmentresult/${id}`);
};

const fetchCompletedAssessment = async (id) => {
  return await client.get(`${URL}load/${id}`);
};

const fetchAssessmentHistory = async (id, type, pageSize, page) => {
  if (type === 'person') {
    return await client.post(`${URL}query-by-person`, { personId: id, pageSize: pageSize, page: page });
  } else {
    return await client.post(`${URL}query-by-user`, { userId: id, pageSize: pageSize, page: page });
  }
};

const saveAssessmentEndDate = async (data) => {
  return await client.post(`${URL}set-end-date`, {
    assessmentResultId: data?.id,
    endDate: data?.date,
    reason: data.reason,
  });
};

const saveIncompleteAssessment = async (answersTemplate) => {
  return await client.post(`${URL}incomplete`, answersTemplate);
};

const recalculateAssessment = async (assessment) => {
  return await client.post(`${URL}calculate`, assessment);
};

const fetchAllHistoryAssessments = async (page, pageSize) => {
  return await client.get(`${URL}query/${page}/${pageSize}`);
};

const fetchAllHistoryAssessmentsForManager = async (page, pageSize) => {
  return await client.get(`${URL}query-all/${page}/${pageSize}`);
};

const fetchSearchedAssessments = async (body, page, pageSize) => {
  return await client.post(`${URL}search/${page}/${pageSize}`, body);
};

const fetchAllIncompleteAssessments = async (page, pageSize) => {
  const response = await client.get(`${URL}all-incomplete/${page}/${pageSize}`);
  return response.data;
};

const fetchEndDateReasons = async () => {
  const res = await client.get(`${URL}end-date-reasons`);
  return res.data;
};

const deleteIncompleteAssessment = async (id) => {
  const response = await client.delete(`${URL}incomplete/${id}`);
  return response.data;
};

const deleteResultAssessment = async (id) => {
  const response = await client.delete(`${URL}delete/${id}`);
  return response.data;
};

const saveAssessment = async (data) => {
  const response = await client.post(`${URL}save`, data);
  return response;
};

const deleteAllIncompleteAssessments = async () => {
  const response = await client.delete(`${URL}all-incomplete`);
  return response;
};

const saveState = async (data) => {
  const response = await client.post(`${URL}save-state`, data);
  return response.data;
};

const actions = {
  fetchAnswersAssessment,
  fetchIncompleteAssessment,
  createReassessmentFromCompletedAssessment,
  fetchCompletedAssessment,
  fetchAssessmentHistory,
  saveIncompleteAssessment,
  saveAssessmentEndDate,
  fetchAllHistoryAssessments,
  fetchSearchedAssessments,
  fetchAllIncompleteAssessments,
  deleteIncompleteAssessment,
  deleteResultAssessment,
  saveAssessment,
  deleteAllIncompleteAssessments,
  fetchAllHistoryAssessmentsForManager,
  fetchEndDateReasons,
  recalculateAssessment,
  saveState,
};

export default actions;
